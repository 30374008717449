import React from "react";
import { Box, Container, Typography } from "@mui/material";
import * as styles from "../../styles/AboutPage/Power.module.scss";

const Power = (props) => {
  return (
    <Container>
      <Box
        className={styles.powerWrapper}
        sx={{
          flexDirection: `${props.direction}`,
          "@media screen and (max-width:992px)": {
            flexDirection: "column",
          },
        }}
      >
        <Box className={styles.powerContent}>
          <Typography variant="h2">{props.title}</Typography>
          <Typography variant="h6">{props.descp}</Typography>
        </Box>
        <Box className={styles.powerImage}>
          <img loading="lazy" src={props.image} alt="" />
        </Box>
      </Box>
    </Container>
  );
};

export default Power;
