import React from "react";
import { Box, Typography } from "@mui/material";
import * as styles from "../../styles/Common/FunctionalCard.module.scss";

const FunctionalCard = (props) => {
  const {
    text,
    image,
    border,
    hoverColor,
    width,
    height,
    align,
    padding,
    textOne,
  } = props;
  return (
    <Box
      className={styles.offersCard}
      sx={{
        width: `${width}`,
        height: `${height}`,
        border: `${border}`,
        alignItems: `${align}`,
        padding: `${padding}`,
        "&:hover": {
          backgroundColor: `${hoverColor}`,
        },
      }}
    >
      <Box
        className={styles.offerCardImage}
        sx={{
          border: `${border}`,
        }}
      >
        <img loading="lazy" src={image} alt="" />
      </Box>
      <Box className={styles.offerCardContent}>
        <Typography variant="h6">{text}</Typography>
        <Typography variant="h5">{textOne}</Typography>
      </Box>
    </Box>
  );
};

export default FunctionalCard;
