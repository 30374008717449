import React from 'react'
import { Box, Container, Typography } from "@mui/material";
import WebApp from "../../images/webApp.svg";
import SmartFeatures from "../../images/smartStar.svg";
import Dashboard from "../../images/smartDashboard.svg";
import Administer from "../../images/productClick.svg";
import FunctionalCard from "../Common/FunctionalCard";

import * as styles from '../../styles/AboutPage/Steps.module.scss'

const Steps = (props) => {
    const cardsContent = [
        {
          text: "Customize your website/mobile application",
          image: WebApp,
          border: "1px solid #24D18E",
          hoverColor: "#24D18E33",
          width: "237px",
          height: "125px",
          align: "flex-start",
          padding: "35px 0px"
        },
        {
          text: "Select our built-in smart features",
          image: SmartFeatures,
          border: "1px solid #837BFC",
          hoverColor: "#837BFC33",
          width: "237px",
          height: "125px",
          align: "flex-start",
          padding: "35px 0px"
        },
        {
          text: "Accessible and Smart Dashboard to help manage your store seamlessly",
          image: Dashboard,
          border: "1px solid #F8E044",
          hoverColor: "#F8E04433",
          width: "237px",
          height: "125px",
          align: "flex-start",
          padding: "35px 0px"
        },
        {
          text: "Administer different stores and products in just a click",
          image: Administer,
          border: "1px solid #FB79AF",
          hoverColor: "#FB79AF33",
          width: "237px",
          height: "125px",
          align: "flex-start",
          padding: "35px 0px"
        }
      ];
  return (
    <Box className={styles.stepsWrapper}>
      <Container>
        <Box className={styles.stepsHeading}>
          <Typography variant="h6">{props.titleOne}</Typography>
        </Box>
        <Box className={styles.stepsCardsWrapper}>
          {cardsContent.map((content) => (
            <FunctionalCard
              textOne={content.text}
              image={content.image}
              border={content.border}
              hoverColor={content.hoverColor}
              width={content.width}
              height={content.height}
              align={content.align}
              padding={content.padding}
            />
          ))}
        </Box>
      </Container>
    </Box>
  )
}

export default Steps