import React from 'react'
import { Box, Container, Divider, Typography } from '@mui/material'

import * as styles from '../../styles/AboutPage/Why.module.scss'

const Why = () => {
  return (
    <Container>
        <Box className={styles.whyWrapper}>
            <Typography variant='h2'>Why choose Quickxi?</Typography>
            <Typography variant='h6'>
            Since 2020 – Quickxi has been committed to simplifying business operations for restaurant, grocery, eCommerce & on-demand services through ready-made mobile applications. We aim to deliver value to our clients and empower them to launch powerful and branded mobile app ordering systems in no time and at lesser costs.
            <br/>
            Our easy-to-use website creator package would allow you to use tools at their optimum level. After that, you don’t need a web designer or programming skills to make a show-stopping website. We make sure that you don’t have to wander to get the best business model because here at Quickxi we have apps and templates for everything. So don’t wait any longer, Go for our innovative and smart built-in features that will help in optimizing your business to look professional on desktops and mobile devices.</Typography>
        <Divider sx={{
            maxWidth: "400px",
            height: "2px",
            margin: "40px auto",
            background: "red",
            "@media screen and (max-width:428px)":{
              maxWidth: "250px",
              margin: "20px auto",
            }
        }}/>
        </Box>
    </Container>
  )
}

export default Why