import React from "react";
import { Container, Box, Typography, Button } from "@mui/material";
import * as styles from "../../styles/Common/HeroSection.module.scss";
import { Link } from "gatsby";

const HeroSection = (props) => {
  const { title, subTitle, buttonText, image, pageName } = props;
  return (
    <Container>
      <Box className={styles.heroWrapper}>
        <Box
          className={
            pageName === "pricing"
              ? `${styles.pricingHeroContent}`
              : `${styles.heroContent}`
          }
        >
          <Typography variant="h1">{title}</Typography>
          <Typography variantMapping="p">{subTitle}</Typography>
          <Link to="/contact-us/">
            <Button className={styles.heroBtn}>{buttonText}</Button>
          </Link>
        </Box>
        <Box className={styles.heroImage}>
          <img loading="lazy" src={image} alt="hero" />
        </Box>
      </Box>
    </Container>
  );
};

export default HeroSection;
