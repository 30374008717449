import React from "react";
import { Container, Box, Typography } from "@mui/material";
import AppCard from "../Common/AppCard";
import valuesImage from "../../images/coreImage.svg";

import * as styles from "../../styles/AboutPage/Values.module.scss";

const Values = () => {
  const cardContent = [
    {
      title: "Creating value for our client both globally & locally",
      description:
        "We enable our clients to become high performers in their industry through value addition. Also, Quickxi is all about building & fostering long term relationships through understanding & responsiveness.",
    },
    {
      title: "Building customer trust and loyalty",
      description:
        "We encourage feedback so that we can better improve our service and our dedicated support staff is available around the clock for providing timely, consistent and quality responses.",
    },
    {
      title: "Encouraging work-life balance within our team",
      description:
        "Our clients are our assets and so are our team members. Our flexible work arrangements ensure high levels of productivity and promote employees’ well-being.",
    },
    {
      title: "Promoting an inclusive working environment",
      description:
        "We love to work with clients from all around the world. Also Quickxi greatly focuses on ethical work culture for growth. Everyone is welcomed in our team and valued the same.",
    },
  ];
  return (
    <section className={styles.valuesWrapper}>
      <Container>
        <Box className={styles.valuesText}>
          <Typography variant="h2">Our core values are Inspiring!</Typography>
        </Box>
        <Box className={styles.valuesContent}>
          <Box className={styles.valuesCard}>
            {cardContent.map((content) => (
              <AppCard
                title={content.title}
                description={content.description}
              />
            ))}
          </Box>
          <Box className={styles.valuesImage}>
            <img loading="lazy" src={valuesImage} alt="app" />
          </Box>
        </Box>
      </Container>
    </section>
  );
};

export default Values;
