import React from 'react'
import { Box, Container, Typography } from '@mui/material'

import * as styles from '../../styles/AboutPage/Programming.module.scss'

const Programming = () => {
  return (
    <Box className={styles.programmingWrapper}>
        <Container>
        <Box className={styles.programmingContent}>
            <Typography variant='h2'>
                No web programming expertise is required, We have everything ready to use.
            </Typography>
        </Box>
        </Container>
    </Box>
  )
}

export default Programming