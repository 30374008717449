import React from "react";
import Layout from "../Components/Common/Layout";
import HeroSection from "../Components/Common/HeroSection";
import Steps from "../Components/AboutPage/Steps";
import Values from "../Components/AboutPage/Values";
import Programming from "../Components/AboutPage/Programming";
import Why from "../Components/AboutPage/Why";
import Power from "../Components/AboutPage/Power";
import Seo from "../Components/seo";
import { Helmet } from "react-helmet";
// Images
import AboutImage from "../images/aboutImage.svg";
import PowerImage from "../images/power.svg";
import LaunchImage from "../images/launch.svg";
import EmailForm from "../Components/Common/EmailForm";

const about = () => {
  return (
    <Layout>
      <Helmet>
        <meta name="robots" content="index, follow" />
        <meta name="robots" content="max-image-preview:standard" />
      </Helmet>
      <Seo
        title="Everything you want to know About - Quickxi "
        description="Start selling online today with Quickxi's highly customizable pre-made web/mob platform. We offer efficient tools to help expand your business online. "
        tag="index"
        pageUrl="about-us/"
      />
      <HeroSection
        title="About us"
        subTitle="Start selling online today with Quickxi's highly customized pre-made web and mobile applications. We aim to provide you with the best customer experience by building your business presence online. Isn't that a sign of relief? To have an affordable, user-friendly, and feature-packed platform at your disposal? With Quickxi you can easily promote your business, cut down development costs, promote swift delivery and ensure an exceptional customer experience."
        buttonText="Hook me up"
        image={AboutImage}
      />
      <Steps titleOne="Quickxi wants to give you the freedom of creating your website and unique interactive presence. All you need to do is follow these simple steps:" />
      <Programming />
      <Why />
      <Power
        title="The power behind Quickxi – Our team"
        descp="Our multi-talented, diverse and highly knowledgeable team is constantly working to provide its users efficient and powerful features. Most importantly, they are problem solvers who believe in creative approaches and new ways of doing things!"
        image={PowerImage}
      />
      <Power
        title="Our ready to launch smart apps make it possible for businesses"
        descp="Quickxi’s main goal is to offer innovative and impactful solutions, for small-mid level businesses to grow and create customer experiences that are hard to forget."
        image={LaunchImage}
        direction="row-reverse"
      />
      <EmailForm />
      <Values />
    </Layout>
  );
};

export default about;
