import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import * as styles from "../../styles/Common/EmailForm.module.scss";
import { Link } from "gatsby";

const EmailForm = (props) => {
  const { indexprop } = props;
  return (
    <Box
      className={styles.emailBG}
      sx={indexprop === 1 ? { marginTop: "0px" } : { marginTop: "80px" }}
    >
      <Container>
        <Box className={styles.emailWrapper}>
          <Box>
            <Typography variantMapping="p">
              Create your online store today with just one click
            </Typography>
          </Box>
          <Box className={styles.formWrapper}>
            <Link to="/contact-us/">
              <Button className={styles.submitEmailBtn}>
                Book a Free Demo
              </Button>
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default EmailForm;
