import React from 'react'
import { Typography, Box } from '@mui/material'
import * as styles from '../../styles/Common/AppCard.module.scss'

const AppCard = (props) => {
  return (
    <Box className={styles.appCardWrapper}>
        <Box className={styles.appCardHeading}>
        <Typography variant='h6'>{props.title}</Typography>
        </Box>
        <Box className={styles.appCardDesc}>
        <Typography variant='h6'>{props.description}</Typography>
        </Box>
    </Box>
  )
}

export default AppCard